/*!
 * SlickCarousel ver. 1.0
 * Copyright Hirotomo Kambe
 */

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class SlickCarousel {
    constructor(selector) {
        this.element = $(selector);
    }

    /**
     * メイン
     *
     * @return {void}
     */
    exec() {
        this.element.slick({
            dots: false,
            autoplay: true,
            speed: 2000,
            arrows: false,
            //fade: true,
        });
    }
}

export default SlickCarousel;
