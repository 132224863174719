/*!
 * PageLoader ver. 1.0
 * Copyright Hirotomo Kambe
 */

class PageLoader {
    constructor() {}

    /**
     * メイン
     *
     * @return {void}
     */
    exec() {
        // ページのロードが終わった後の処理
        $(window).on('load', function(){
            $('#js-loader').delay(300).fadeOut(300, function() {
                $('*', this).hide();
            });
        });
         
        // ページのロードが終わらなくても10秒たったら強制的に処理を実行
        $(function(){ setTimeout(function() {
            $('#js-loader').delay(300).fadeOut(300, function() {
                $('*', this).hide();
            });
        }, 10000); });
    }
}

export default PageLoader;
