/**
 * SmoothScroll
 *
 * version 1.0
 * Copyright Hirotomo Kambe
 */
class SmoothScroll {
    constructor() {}

    /**
     * exec
     *
     * @return {void}
     */
    exec() {
        //スムーススクロール
        this.smoothScroll();
    
        //ページトップスムーススクロール
        this.backToTop();
    
        //別ページからのスムーススクロール
        this.smoothScrollFromOtherPage();
    }

    /**
     * smoothScroll 
     * スムーススクロール
     *
     * @return {void}
     */
    smoothScroll() {
        var target = '.smooth-scroll';
        var speed = 700; //ms
        var position = 0;
        var offset = 0;
        var href = '';

        $(target).each(function() {
            $(this).filter(function(){

                //ホストを取得
                var pattern = location.host;
                var regexp = new RegExp('(http:|https:)?//' + pattern + '(\/)?', 'i');

                //URIからホスト部分を削除(自ページ内のみを対象）
                var href = $(this).attr('href').replace(regexp, '');

                //判定
                var result = href.match(/^#[a-zA-Z0-9]+/);

                return result;
            }).click(function(event) {
                //初期動作無効
                event.preventDefault();
                event.stopPropagation();

                //移動先／スピード
                href = this.hash;

                //座標取得
                if($(href).length) {
                    position = $(href).offset().top;
                }
                $('body, html').animate({
                    scrollTop: position + offset 
                }, speed, 'swing');
                return false;
            });
        });
    }

    /**
     * backToTop
     * ページトップへ戻る
     *
     * @return {void}
     */
    backToTop() {
        var back_to_top_selector = '#js-back-to-top';
        var show_flag = false;
        var back_to_top_button = $(back_to_top_selector);
        var pos_top = '0px';
        var pos_bottom = '-100px';
    
        back_to_top_button.css('bottom', pos_bottom);
        show_flag = false;
    
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                if (show_flag === false) {
                    show_flag = true;
                    back_to_top_button.stop().animate({
                        'bottom': pos_top
                    }, 200);
                }
            } else {
                if (show_flag) {
                    show_flag= false;
                    back_to_top_button.stop().animate({
                        'bottom': pos_bottom
                    }, 200);
                }
            }
        });
    }

    /**
     * smoothScrollFromOtherPage 
     * 別ページからのスムーススクロール
     *
     * @return {void}
     */
    smoothScrollFromOtherPage() {
        var headerHeight = 95;
        var param = '?aid=';
        $(window).on('load', function() {
            var url = $(location).attr('href');
            if(url.indexOf(param) !== -1){
                var id = url.split(param);
                var $target = $('#' + id[id.length - 1]);
                if($target.length){
                    var pos = $target.offset().top-headerHeight;
                    $('html, body').animate({scrollTop:pos}, 400);
                }
            }
        });
    }
}

export default SmoothScroll; 
