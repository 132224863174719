"use strict";

// モジュール
require('jquery.cookie');
//require('font-awesome/css/font-awesome.css');

import PageLoader from './module/PageLoader.js';
import ChangeFontSize from './module/ChangeFontSize.js';
import SmoothScroll from './module/SmoothScroll.js';
import SlickCarousel from './module/SlickCarousel.js';
import Drawer from './module/Drawer.js';
import AutoKana from './module/AutoKana.js';
import MatchHeight from './module/MatchHeight.js';

// ページローダー
var page_loader = new PageLoader();
page_loader.exec();

// フォントサイズ変更
var change_font_size = new ChangeFontSize();
change_font_size.exec();

// ドロワ
var drawer = new Drawer();
drawer.exec();

// スムーススクロール
var smooth_scroll = new SmoothScroll();
smooth_scroll.exec();

// 住所自動入力
// https://github.com/yubinbango/yubinbango

// スムーススクロール
var auto_kana = new AutoKana();
auto_kana.exec();

// 高さ揃え
var match_height = new MatchHeight();
match_height.exec();

// Slick
var slick_carousel = new SlickCarousel('.slick-slider');
slick_carousel.exec();

// h2タイトル
$('.p-editor h1').each(function() {
    $(this).wrapInner('<span></span>');
});

// テーブル
$('.p-editor table').each(function() {
    $(this).wrap('<div class="p-editor-table-wrap"></div>');
});
