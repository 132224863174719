/*!
 * MatchHeight ver. 1.0
 * Copyright Hirotomo Kambe
 */

import JqueryMatchHeight from '../vendor/jquery.matchHeight.js';

class MatchHeight {
    constructor() {}

    /**
     * メイン
     *
     * @return {void}
     */
    exec() {
        var options = {
        };

        $('.matchheight .text').matchHeight(options);
    }
}

export default MatchHeight;
