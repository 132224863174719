/**
 * ChangeFontSize
 *
 * version 1.0
 * Copyright Hirotomo Kambe
 */

class ChangeFontSize {
    constructor() {
        this.item_active = 'is-active';
        this.item = '.p-change-font-size__items__item';
        this.prefix = 'js-indicator';
        this.target = 'body';
        this.cookie_key = 'font-size';
        this.default_size = this.prefix + '--md';
    }

    /**
     * メイン
     *
     * @return {void}
     */
    exec() {
        var _this = this;
    
        // クリックイベント
        $( _this.item ).each( function() {
            $( '.switch', this ).on( 'click', function(e) {
                e.preventDefault();
    
                // サイズ取得
                //var href = $( this ).attr( 'href' );
                //var size = href.replace( /#/, '');
                var size = $( this ).attr( 'id' );
                //console.log(this);
    
                // 設定
                _this.setSize(size);
    
                return false;
            });
        });
    
        // 初期設定
        this.setSize($.cookie(this.cookie_key));
    }

    /**
     * 対象とサイズの受け取り、設定
     *
     * @return {void}
     */
    setSize(size) {
        var _this = this;
    
        if (typeof size === 'undefined' || size === '') {
            size = this.default_size;
        }
    
        // リセット
        $(this.target).removeClass(this.prefix + '--sm');
        $(this.target).removeClass(this.prefix + '--md');
        $(this.target).removeClass(this.prefix + '--lg');
        $(this.item).each( function() {
            $(this).removeClass( _this.item_active );
        });
    
        // サイズ設定
        $(this.target).addClass(size);
        $(this._(size)).parent().addClass(_this.item_active);
    
        // Cookie設定
        $.cookie(this.cookie_key, size);
    };

    _(name) {
        return '#' + name;
    };
}

export default ChangeFontSize;
