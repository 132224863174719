/*!
 * AutoKana ver. 1.0
 * Copyright Hirotomo Kambe
 */

import JqueryAutoKana from '../vendor/jquery.autoKana.js';

class AutoKana {
    constructor() {}

    /**
     * メイン
     *
     * @return {void}
     */
    exec() {
        var target = '.autokana';
        var suffix = '__kana';
        var is_katakana = false;//true：カタカナ、false：ひらがな（デフォルト）
        var pair = [];
        var from, to;

        if($.fn.autoKana) {
            if ($(target).length > 0) {
                $(target).each(function() {
                    pair.push('#' + $(this).attr('id'));
                });

                for (var i = 0; i < pair.length; i++) {
                    from = pair[i];
                    to = pair[i] + suffix;
                    $.fn.autoKana(from, to, {
                        katakana : is_katakana
                    });
                }
            }
        }
    }
}

export default AutoKana;
