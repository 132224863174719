/*!
 * Drawer ver. 1.0
 * Copyright Hirotomo Kambe
 * Special Thanks
 * https://www.willstyle.co.jp/blog/150/
 * https://increment-log.com/css-transition-drawer/
 */

class Drawer {
    constructor() {}

    /**
     * メイン
     *
     * @return {void}
     */
    exec() {
        var touch = false;
        var canvas = 'body';
        var trigger = '#js-drawer__trigger';
        var overlay = '#js-drawer__overlay';
        var content = '#js-drawer__content';
        var bodyclass = 'js-drawer__body--hidden';
        var opendclass = 'js-drawer--opened';
        var activeclass = 'js-drawer--active';

        $(trigger).on('click touchstart',function(e){
            var result = true;
            switch (e.type) {
                case 'touchstart':
                    drawerToggle();
                    touch = true;
                    result = false;
                break;
                case 'click':
                    if(!touch) {
                        drawerToggle();
                    }
                    result = false;
                break;
            }
            return result;
        });
    
        var togglearray = [overlay, content + ' a'];
        //$(togglearray.join()).on('click touchstart',function(e){
        $(togglearray.join()).on('click',function(e){
            //e.preventDefault();
            e.stopPropagation();
            drawerToggle();
        });
    
        function drawerToggle(){
            $(canvas).toggleClass(opendclass);
            $(canvas).parent().toggleClass(activeclass);
            $(canvas).toggleClass(bodyclass);
            touch = false;
        }
    }
}

export default Drawer;
